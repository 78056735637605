import { Link } from 'react-router-dom';
import '../Back-end/responsive/style.css';
import logo from '../../Admin/resource/boy2.png';
const Powered = () => {
  return (
    <div>
      <h2>
        <Link to="/" target="_blank" className="powered">
          Powered by Talkr <img src={logo} alt="icon" id="circlelogo" />
        </Link>
      </h2>
    </div>
  );
};

export default Powered;
