import { Link } from 'react-router-dom';
import Logo from './boy.png';
import '../../../Back-end/responsive/style.css';
const Brand_logo = () => {
  return (
    <div>
      <Link to="/">
        <img src={Logo} alt="brand" id="brlogo" />
      </Link>
    </div>
  );
};

export default Brand_logo;
