import { useRef, useEffect } from 'react';
import '../../Back-end/responsive/Counter.css';

function PageCounter() {
  const pageviewsCount = useRef(null);
  const visitsCount = useRef(null);

  useEffect(() => {
    if (sessionStorage.getItem('visit') === null) {
      // New visit and pageview
      updateCounter('type=visit-pageview');
      sessionStorage.setItem('visit', 'x');
    } else {
      // Pageview
      updateCounter('type=pageview');
    }
  }, []);

  function updateCounter(type) {
    fetch('localhost:3002/api?' + type) // Dynamic request with URL parameter
      .then(res => res.json())
      .then(data => {
        if (pageviewsCount.current && visitsCount.current) {
          pageviewsCount.current.textContent = data.pageviews; // Display pageviews to user
          visitsCount.current.textContent = data.visits; // Display visits to user
        }
      });
  }

  return (
    <div className="body">
      <main id="container">
        <div>
          <span ref={pageviewsCount}>Loading...</span>
          <h4>PageViews</h4>
        </div>
        <div>
          <span ref={visitsCount}>Loading...</span>
          <h4>Visits</h4>
        </div>
      </main>
    </div>
  );
}

export default PageCounter;
