import { Link } from 'react-router-dom';
import '../../../Back-end/responsive/style.css';
const BrandName = () => {
  return (
    <div>
      <Link to="/" className="no-underline">
        <h1 style={{ background: '#ff3a22' }}>
          <span style={{ color: '#0f2862' }}>Talkr</span>
          <span style={{ color: '#fffff0' }}>.Stream</span>
        </h1>
      </Link>
    </div>
  );
};

export default BrandName;
