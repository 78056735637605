import { Link } from 'react-router-dom';
import logo from '../resource/link (2).png';

const Insta = () => {
  return (
    <div>
      <Link to="https://www.instagram.com/talkr.solution/" target="_blank">
        <img width="375" height="375" src={logo} alt="instagram-new" />
      </Link>
    </div>
  );
};

export default Insta;
