import { Link } from 'react-router-dom';
import '../../../../Admin/Back-end/responsive/style.css';

const HorzMenu = () => {
  return (
    <div className="HorzMenu">
      <Link to="/be/shop" className="no-underline">
        <h2>Furniture</h2>
      </Link>

      <Link to="/be/shop" className="no-underline">
        <h2>Hardware</h2>
      </Link>

      <Link to="/be/shop" className="no-underline">
        <h2>Paint</h2>
      </Link>

      <Link to="/be/shop" className="no-underline">
        <h2>Plywood</h2>
      </Link>
    </div>
  );
};

export default HorzMenu;
