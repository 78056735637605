import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Webapp from './Admin/Back-end/Webapp';
import Game from './Admin/Front-end/center/pages/Game';
import SubDo from './Admin/Back-end/DB/SubDo';
import Options from './Admin/Back-end/session/LogedIn';
import BE from './Sub/Front-end/center/pages/BE';
import Social from './Admin/Front-end/center/pages/Social';
import Header from './Sub/Front-end/header/Header2';
import Shop from './Sub/Front-end/center/pages/Shop';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Webapp />} />

        <Route path="/be" element={<BE />} />
        <Route element={<Header />}>
          <Route path="/be/shop" element={<Shop />} />
        </Route>

        <Route path="/cloud" />
        <Route path="/cloud/wp-admin" />
        <Route path="/cloud/about" />
        <Route path="/cloud/blog" />
        <Route path="/cloud/service" />
        <Route path="/cloud/contact" />
        <Route path="/cloud/open" />

        <Route element={<SubDo />}>
          <Route path="/games" element={<Game />} />

          <Route path="/app" element={<Options />} />
          <Route path="/social" element={<Social />} />

          <Route
            path="*"
            element={
              <div id="red">
                {' '}
                Simply Go to{' '}
                <a href="http://talkar.stream" id="error">
                  {' '}
                  talkar.stream{' '}
                </a>
              </div>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
