import '../../../Back-end/responsive/style.css';

import PageCounter from '../../stat/Counter';
import Insta from '../../../resource/Insta';
import Yt from '../../../resource/Yt';
const Social = () => {
  return (
    <div className="App">
      <Insta />
      <Yt />
      <PageCounter />
    </div>
  );
};

export default Social;
