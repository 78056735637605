import BrandName from '../center/brand/Brand_name';
import Brand_logo from '../center/brand/Brand_logo';
import End from './End';
import SocialLink from './SocialLink';
import '../../Back-end/responsive/style.css';

const Header = () => {
  return (
    <div style={{ backgroundColor: '#cacac7' }}>
      <div className="App">
        <BrandName />
      </div>
      <div style={{ float: 'right' }}>
        <SocialLink />
      </div>

      <div id="brandlogo">
        <Brand_logo />
      </div>
      <div className="App">
        <End />
      </div>
    </div>
  );
};

export default Header;
