import { Link } from 'react-router-dom';
import '../../../../';

const BrandName = () => {
  return (
    <div className="title-style">
      <h1>
        <Link to="/be" className="no-underline">
          <span style={{ color: '#FC4A1A' }}>BABA </span>
          <span style={{ color: '#beef00' }}>ENTERPRISES</span>
        </Link>
      </h1>
      <h2>GSTIN- 10CFCPD7539E2ZY</h2>
    </div>
  );
};

export default BrandName;
