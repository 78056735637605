import '../../../Admin/Back-end/responsive/style.css';
import User from '../../Back-end/user/User';
import BrandName from '../center/brand/BrandName';
import { FaPhone } from 'react-icons/fa';
import { FaLocationCrosshairs } from 'react-icons/fa6';
import { Ad } from '../../../Admin/Front-end/footer/Ad';
import { Outlet } from 'react-router-dom';

const Header = () => {
  return (
    <div style={{ backgroundColor: '#ffde22' }}>
      <div className="header-item">
        <div>
          <User />
        </div>
        <div className="App">
          <h1>Welcome To Store</h1>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <h3>Proprietor- Amarnath Upadhayay</h3>
            <h3>
              <a
                href="tel:8340267534"
                className="no-underline"
                style={{ color: '#FC4A1A' }}
              >
                <FaPhone /> Contact- 8340267534
              </a>
            </h3>
          </div>
        </div>

        <div>
          <h3>
            <FaLocationCrosshairs />
            In Front of Pashupalan Office,
            <br /> Housing Colony, Vidya Mandir Road,
            <br /> Purnia, Bihar <br /> PIN- 854301
          </h3>
        </div>
      </div>

      <div className="App">
        <BrandName />
      </div>

      <div style={{ position: 'fixed', bottom: 10, right: 10 }}>
        <Ad />
      </div>
      <Outlet />
    </div>
  );
};

export default Header;
