import '../../../Sub/Back-end/responsive/fiverr.css'; // Make sure to create a corresponding CSS file
import { Link } from 'react-router-dom';
const LinkPreview = ({ title, description, image }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className="link-preview">
        <Link to="https://www.facebook.com" target="_blank">
          <img src={image} alt={title} className="preview-image" />
        </Link>

        <div className="preview-content">
          <h4 className="preview-title">{title}</h4>
          <p className="preview-description">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default LinkPreview;
