import LinkPreview from './MapPre';
import map from '../../../Admin/resource/map.png';

const Location = () => {
  return (
    <div>
      <h2 className="Options"> 🗺️ Locate Us !</h2>
      <LinkPreview
        title="Google Map"
        description="Baba Enterprises Purnia"
        image={map}
      />
    </div>
  );
};
export default Location;
