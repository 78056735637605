import { Link } from 'react-router-dom';
import logo from '../resource/link (1).png';
const Yt = () => {
  return (
    <div>
      <Link
        to="https://youtube.com/@talkr.solution?si=G809XIa9YNWUuRzH"
        target="_blank"
      >
        <img width="375" height="375" src={logo} alt="youtube-play" />
      </Link>
    </div>
  );
};

export default Yt;
