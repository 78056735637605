import { Link } from 'react-router-dom';
import user from '../../../Admin/resource/user.png';
const User = () => {
  return (
    <div>
      <Link to="/be">
        <img width="94" height="94" src={user} alt="user-male-circle" />
      </Link>
    </div>
  );
};

export default User;
