import LinkPreview from './LinkPre';

const Fiverr = () => {
  return (
    <div>
      <h2 className="blue">Make your own webPage</h2>
      <LinkPreview
        title="S. Keshri"
        description="Web Devloper"
        image="https://i.postimg.cc/jdV2yDjc/moqups.jpg" // Replace with your image path
      />
    </div>
  );
};

export default Fiverr;
