import Header from '../Front-end/header/Header';
import Fiverr from '../Front-end/Links/Fiverr';
import { Ad } from '../Front-end/footer/Ad';
import HorzMenu from '../Front-end/center/top menu/HorzMenu';

const Webapp = () => {
  return (
    <div className="page-format">
      <Header />

      <HorzMenu />
      <div className="App">
        <Fiverr />
      </div>

      <div style={{ position: 'fixed', bottom: 10, right: 10 }}>
        <Ad />
      </div>
    </div>
  );
};

export default Webapp;
