import { Link } from 'react-router-dom';
import '../responsive/style.css';
const Options = () => {
  return (
    <div className="App">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Link to="/games" className="no-underline">
          <div className="pink">Game</div>
        </Link>
      </div>
    </div>
  );
};

export default Options;
