import { FaIndianRupeeSign, FaWhatsapp } from 'react-icons/fa6';
import '../../Back-end/responsive/style.css';

export const Ad = () => {
  return (
    <div className="app">
      <h4>
        <span>
          <a
            href="https://wa.me/7808870073?text=Hii..%20Please%20Make%20a%20website%20for%20me."
            target="_blank"
            rel="noreferrer"
            className="pink"
          >
            <FaWhatsapp /> Website Devloper
          </a>
        </span>
        <span style={{ color: '#565656' }}>
          <br />
          Free Custom Domain with 1 year Free Hosting
          <br />
          and SSL Certificate for <FaIndianRupeeSign />
          2000/-
        </span>
      </h4>
    </div>
  );
};
