import { Link } from 'react-router-dom';
import Header from '../../header/Header2';
import HorzMenu from '../top menu/HorzMenu2';
import FacebookLink from '../../Links/FacebookLink';
import Location from '../../Links/GMap';
import wa from '../../../../Admin/resource/whatsapp.png';
import '../../../../Admin/Back-end/responsive/style.css';
import Powered from '../../../../Admin/Compo/Powered';

const BE = () => {
  return (
    <div className="page-format">
      <Header />
      <HorzMenu />
      <div className="App">
        <Link to="tel:+917739623595" target="_blank">
          <img width="188" height="188" src={wa} alt="whatsapp" />
        </Link>
        <Location />
        <FacebookLink />
        <Powered />
      </div>
    </div>
  );
};

export default BE;
