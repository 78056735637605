import '../../../../Admin/Back-end/responsive/style.css';
import { FaPhone } from 'react-icons/fa6';
import { FaWhatsapp } from 'react-icons/fa';

const Shop = () => {
  return (
    <div className="page-format">
      <h1>
        <a
          href="https://wa.me/917739623595?text=Hii..%20I%20have%20some%20order%20to%20place."
          target="_blank"
          rel="noreferrer"
          className="no-underline"
          style={{ color: '#0f931a' }}
        >
          <FaWhatsapp /> Order on WhatsApp
        </a>
      </h1>
      <h1>
        <a
          href="tel:7654334486"
          rel="noreferrer"
          className="no-underline"
          style={{ color: '#FC4A1A' }}
        >
          <FaPhone /> Order by Call
        </a>
      </h1>
      <h1>
        <a
          href="https://maps.app.goo.gl/Uj5migom6FDoT2n2A"
          target="_blank"
          rel="noreferrer"
          className="no-underline"
          style={{ color: '#FFFFF0' }}
        >
          {' '}
          Visit Our Outlet
        </a>
      </h1>
    </div>
  );
};

export default Shop;
