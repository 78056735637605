import { FaInstagram, FaYoutube } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import '../../Back-end/responsive/style.css';

const SocialLink = () => {
  return (
    <div>
      <Link
        to="https://www.instagram.com/talkr.solution/"
        target="_blank"
        className="space"
        id="soLink"
      >
        <FaInstagram />
      </Link>
      <Link
        to="https://youtube.com/@talkr.solution?si=G809XIa9YNWUuRzH"
        target="_blank"
        className="space"
        id="soLink"
      >
        <FaYoutube />
      </Link>
    </div>
  );
};

export default SocialLink;
