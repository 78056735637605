import LinkPreview from './LinkPre';
import fb from '../../../Admin/resource/facebook.png';

const FacebookLink = () => {
  return (
    <div>
      <h2 className="Options">We are available on Facebook also !</h2>
      <LinkPreview
        title="Fb Profile"
        description="Baba Enterprises"
        image={fb}
      />
    </div>
  );
};

export default FacebookLink;
