import { Link } from 'react-router-dom';
const Game = () => {
  return (
    <div className="App" style={{ display: 'flex', flexDirection: 'column' }}>
      <Link to="https://keshrigames.surge.sh" target="_blank">
        <img
          width="94"
          height="94"
          src="https://img.icons8.com/3d-fluency/94/knight.png"
          alt="knight"
        />
      </Link>
      <Link to="https://learntowrite.surge.sh" target="_blank">
        Writing Prectice For Children
      </Link>
    </div>
  );
};

export default Game;
