import { Link } from 'react-router-dom';
import '../../Back-end/responsive/style.css';
const End = () => {
  return (
    <div>
      <Link to="/" className="no-underline">
        <h1 style={{ background: '#0f2862', color: '#fffff0' }}>Welcome</h1>
      </Link>
    </div>
  );
};

export default End;
