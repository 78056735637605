import { Link } from 'react-router-dom';
import '../../../Back-end/responsive/style.css';

const HorzMenu = () => {
  return (
    <div className="HorzMenu">
      <Link to="/social" className="no-underline">
        <h3>Social</h3>
      </Link>

      <Link to="" className="no-underline">
        <h3>Pages</h3>
      </Link>

      <Link to="/" className="no-underline">
        <h3>App</h3>
      </Link>

      <Link to="/" className="no-underline">
        <h3>Messanger</h3>
      </Link>
    </div>
  );
};

export default HorzMenu;
