import { Outlet } from 'react-router-dom';

import { Ad } from '../../Front-end/footer/Ad';
import '../responsive/style.css';
import BrandName from '../../Front-end/center/brand/Brand_name';

const SubDo = () => {
  return (
    <div style={{ backgroundColor: '#0f2862' }}>
      <BrandName />
      <Outlet />

      <div style={{ position: 'fixed', bottom: 10, right: 10 }}>
        <Ad />
      </div>
    </div>
  );
};

export default SubDo;
